var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column p-5"},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"level is-mobile"},[_vm._m(0),_c('div',{staticClass:"level-right"},[_c('b-button',{staticClass:"ml-4",attrs:{"active":_vm.filters.showVehiclesNotUpdatedIn24h,"type":_vm.filters.showVehiclesNotUpdatedIn24h ? 'is-link' : ''},on:{"click":_vm.handleShowVehiclesNotUpdatedIn24hFilter}},[_vm._v("Vehicles not updated in 24h")]),_c('div',{staticClass:"ml-4"},[_c('b-select',{attrs:{"placeholder":"Filter by Connectivity"},on:{"input":_vm.handleDongleConnectivityFilter},model:{value:(_vm.filters.donglePluggedStatus),callback:function ($$v) {_vm.$set(_vm.filters, "donglePluggedStatus", $$v)},expression:"filters.donglePluggedStatus"}},[_c('option',{key:"connected",attrs:{"value":"connected"}},[_vm._v(" Connected vehicles ")]),_c('option',{key:"disconnected",attrs:{"value":"disconnected"}},[_vm._v(" Disconnected vehicles ")])])],1),_c('b-button',{staticClass:"ml-4",attrs:{"active":_vm.filters.showVehiclesNotUpdatedIn24h ||
                  _vm.filters.donglePluggedStatus !== undefined,"type":_vm.filters.showVehiclesNotUpdatedIn24h ||
                _vm.filters.donglePluggedStatus
                  ? 'is-link'
                  : ''},on:{"click":_vm.handleResetFilter}},[_vm._v("Reset filters")])],1)]),_c('hr'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[(
                !_vm.tableProps.loading &&
                  _vm.allVehiclesSummary &&
                  _vm.allVehiclesSummary.length
              )?_c('div',{staticClass:"grid-wrapper"},[_c('div',{staticClass:"grid"},[_c('ag-grid-vue',{staticClass:"ag-theme-alpine",staticStyle:{"width":"100%","height":"100%"},attrs:{"domLayout":"autoHeight","columnDefs":_vm.gridProps.columnDefs,"rowData":this.allVehiclesSummary,"defaultColDef":_vm.gridProps.defaultColDef},on:{"grid-ready":_vm.onGridReady}})],1)]):(_vm.tableProps.loading)?_c('div',[_c('i',{staticClass:"mdi mdi-information-outline pr-2"}),_vm._v("Loading... ")]):_c('div',[_c('i',{staticClass:"mdi mdi-information-outline pr-2"}),_vm._v("No Summary to display. ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"title is-5 mt-2"},[_vm._v("Diagnostics: Analyze Vehicles")])])
}]

export { render, staticRenderFns }